@font-face{
    font-family: "Montserrat";
    src: url("./assets/fonts/Montserrat-Regular.ttf")
}
@font-face{
    font-family: "Montserrat SemiBold";
    src: url("./assets/fonts/Montserrat-SemiBold.ttf")
}

@font-face{
    font-family: "Open Sans Light";
    src: url("./assets/fonts/OpenSans-Light.ttf")
}
@font-face{
    font-family: "Open Sans SemiBold";
    src: url("./assets/fonts/OpenSans-SemiBold.ttf")
}
html,body{
    color: #001833;
    scroll-behavior: smooth;
}
h5{
    line-height: 2rem;
    font-weight: normal;    
}
.sticky-top-custom {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
    background-color: #007afe;
}
.navbar {
    position: sticky;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 2.125rem; */
    padding: 0.85rem 1rem;
    transition: all 0.5s;
}
.navlinks{
    font-family: 'Open Sans SemiBold';
    font-size: 1rem;
    color: #fff;
}
.main-div-below-nav{
    padding: 8rem 0rem
}
.main-title {
    font-family: Montserrat;
    font-weight: bold;
    color: #0051b5;
}

.div-delow-video{
    background:linear-gradient(#007afe 0%, #00419d 100%, #0043a1 100%);
    height: 4.8125rem;
    max-width: 85.26%;
    margin: 0 auto;
    margin-top: -8.4%;
}
.about-title{
    font-family: Montserrat;
    font-weight: 300;
    font-size: 2rem;
    letter-spacing: 0.02em;
    text-align: center;
    color: #001833;

}
.about-subtitles{
    font-family: Montserrat;
    font-weight: normal;
    letter-spacing: 0.02em;
    text-align: left;
    color: #0051b5;
}
.about-subtitles-text{
    font-family: Montserrat;
    font-weight: normal;
    font-size: 20px;
    line-height: 35px;
    text-align: left;
    color: #001833;
    }
.contact-link{
    font-size: 1.125rem;
    color: #fff;
    margin: auto;
    text-align: center;
    width: 100%;
    font-family: 'Open Sans Light';
    font-style: italic;
    font-weight: '500';
    padding: 0px;
    
}
.contact-link-a {
    text-decoration: underline;
    font-style: italic;
    color: inherit;
}
.contact-link-a:hover{
    text-decoration: underline;
    font-style: italic;
    color: #f5c9ff;
}
.contact {
    width: 39.375rem;
    padding: 3.75rem 0rem;
    border-radius: 0.5rem;
    margin-top: -6rem;
    background-color: #ffffff;
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.16);
}
.contact-label{
    font-family: Montserrat;
    font-size: 1.25rem;
}
.player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
  }
.react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
.iconButton{
    display: flex;
    width: 2.75rem;
    height: 2.75rem;
    border-width: 0px;
    background-color: #fff;
    border-radius: 0.125rem;
    justify-content: center;
    margin: 2.875rem 0.625rem;
  }
 .submitButton{
    height: 4.0625rem;
    font-size: 1.125rem;
    background-color: #0051B5;
    border-width: 0px;
 } 
@media (max-width: 768px) { 
    #home{
        background-position: 100% 0px;
        background-size: 55% auto;
    }
    .imgUp{
        margin-top: -6rem;
    }
    .video-title{
        padding-top: 0.5rem;
        padding-bottom: 2.625rem;
    }
    .main-title{
        padding: 2.5rem 1rem 0rem 1rem;
        font-size: 1.5rem;
        line-height: 2rem;
    }
    .about-title{
        font-family: Montserrat;
        font-weight: 300;
        font-size: 20px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #001833;
    }
    .about-title-text{
        font-family: Montserrat;
        font-weight: normal;
        font-size: 14px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #001833;

    }
    .about-subtitles{
        text-align: center;
        color: #0051b5;
        
    }
    .about-subtitles-text{
        font-size: 1rem;
        line-height: 25px;
        text-align: center;
        color: #001833;
        padding: 0 0.92rem;
    }
    .main-div-below-nav {
        padding: 8rem 0rem 0rem 0rem;
    }
    .div-delow-video {
        background: -webkit-linear-gradient(#007afe 0%, #00419d 100%, #0043a1 100%);
        background: linear-gradient(#007afe 0%, #00419d 100%, #0043a1 100%);
        height: 2.8125rem;
        max-width: 85.26%;
        margin: 0 auto;
        margin-top: -8%;
    }
    .contact-link{
        font-size: 0.65rem;
        color: #fff;
        margin: auto;
        text-align: center;
        width: 100%;
        font-family: 'Open Sans Light';
        font-style: italic;
        font-weight: '500';
        padding: 0px;
    }
 }

@media (min-width: 992px) { 

 }
 @media (min-width: 768px) {

}
@media (min-width: 1024px) { 
    .margin-10{
        margin-top: 8rem;
    }
}
